import React, { useEffect, useState } from "react";
import Carousel from 'react-elastic-carousel';
import { Grid, Box, Button } from "@mui/material";
import './HomeOurUnique.scss'
import expertise_icon from '../../../assets/expertise-icon.png';
import expertise_img_one from '../../../assets/expertise-img1.png';
import expertise_img_two from '../../../assets/expertise-img2.png';
import expertise_img_three from '../../../assets/expertise-img3.png';
import expertise_img_four from '../../../assets/expertise-img4.png';
import Container from '@material-ui/core/Container';


export default function HomeOurUnique() {

  // breakpoints
  const breakPoints = [

    { width: 1, itemsToShow: 2 }, // works for 428

    { width: 428, itemsToShow: 2 }, // works for 600

    { width: 500, itemsToShow: 2 }, // works for 905

    { width: 768, itemsToShow: 3 }, // works for 1240 and above

    { width: 1100, itemsToShow: 4 }

  ]


  const state = {
    items: [
      {
        id: 1,
        image: expertise_img_one,
        title: "flexible mobility"
      },
      {
        id: 2,
        image: expertise_img_two,
        title: "insignia"
      },
      {
        id: 3,
        image: expertise_img_three,
        title: "sustainability"
      },
      {
        id: 4,
        image: expertise_img_four,
        title: "sustainability",
      },

    ]
  }

  const { items } = state;
  return (

<Container maxWidth="xl">
    <Grid className="unique-cls">
      <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Grid item xl={2} lg={2} md={3} sm={4} xs={8} className='d-flex'>
          <h3 className='title'>
            <img src={expertise_icon} alt="expertise icon" />
            <span>our unique expertise</span>
          </h3>
        </Grid>
        <Grid container item xl={10} lg={10} md={9} sm={12} xs={12}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="unique-block">
            <Carousel breakPoints={breakPoints} enableAutoPlay autoPlaySpeed={1500} itemPadding={[0, 9]} outerSpacing={40}>
              {
                items.map((item, i) => <Item key={i} item={item} />)
              }
            </Carousel>
          </Grid>
        </Grid>

         {/* More Blogs Text */}
         <Grid container>
         
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Button className='common-btn'>learn more</Button>
          </Grid>
         
        </Grid>
       
      </Grid>
      </Grid>
    </Container>


  )


  function Item(props) {
    console.log("data", props)
    return (
      <>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="unique-block">
          <Box class="bg-img">
            <div className='sub-title'>{props.item.title} </div>
            <img src={props.item.image} alt="flexible mobility" />
          </Box>
        </Grid>

      </>
    )
  }



}


