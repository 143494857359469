import React, { useEffect, useState } from "react";
import Carousel from 'react-elastic-carousel';
import { Grid, Box, Card, Button, Flex } from "@mui/material";
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import ImageOne from '../../../assets/our-unelenting-img1.png';
import ImageTwo from '../../../assets/our-unelenting-img2.png';
import ImageThree from '../../../assets/our-unelenting-img3.png';
import ImageFour from '../../../assets/our-unelenting-img4.png';
import unrelenting_icon from '../../../assets/unrelenting-icon.png';
import './HomeOurUnelenting.scss';
import clsx from 'clsx';
import Container from '@material-ui/core/Container';




export default function HomeOurUnelenting() {
  
    // breakpoints
    const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 599, itemsToShow: 2 },
        { width: 904, itemsToShow: 4 },
        { width: 1239, itemsToShow: 4 }
    ];


  const state = {
      items: [
          {
              id: 1,
              image: ImageOne,
              bookmark: 'FEATURED',
              title: "Out Now!",
              // pubDate: 'June 16',
              description: "flexible mobility white paper : building budgets"
          },
          {
              id: 2,
              image: ImageTwo,
              bookmark: 'FEATURED',
              title: "Hear us on Friday’s",
              // pubDate: 'June 16',
              description: "expert series: moving in podcast"
          },
          {
              id: 3,
              image: ImageThree,
              bookmark: 'FEATURED',
              title: "mobility supply chain: what’s happening in the wider industry?",
              pubDate: 'June 16',
              description: "Our latest Mobility Supply Chain Guide looks at the specific supply chain challenges within the wider industry, what Cartus is doing to meet these challenges, and key recommendations to help organizations."
          },
          {
              id: 4,
              image: ImageFour,
              bookmark: 'FEATURED',
              title: "Out Now!",
              // pubDate: 'June 16',
              description: "flexible mobility white paper : building budgets"
          },
          {
            id: 5,
            image: ImageFour,
            bookmark: 'FEATURED',
            title: "Out Now!",
            // pubDate: 'June 16',
            description: "flexible mobility white paper : building budgets"
        },
        
      ]
  }

  const { items } = state;
  return (
    
      <div className="unrelenting-cls">
        <Container maxWidth="xl">
        <Grid >
              <Grid container item xl={9} lg={6} md={7} sm={8} xs={10}>
                  <h2 className='title'>
                      <img src={unrelenting_icon} alt="expertise icon" />
                      <span>our unrelenting thoughts & impressions</span>
                  </h2>
              </Grid>
              <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <h3 className="sub-title">empower yourself of all latest happenings</h3>
                  </Grid>
              </Grid>
              <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} className="at-center">
                  <Grid item xl={1} lg={1} md={1} sm={1} xs={0}></Grid>
                  <Grid item xl={11} lg={11} md={11} sm={11} xs={12}>
                      <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} spacing={2} className="carousel-main-cont">
                        
                          <Carousel breakPoints={breakPoints} itemPadding={[0, 20, 0, 0]} 
                          renderPagination={({ pages, activePage, onClick }) => {
                        return (              
                            <div className="carousel-button" direction="row">             
                            {pages.map(page => {              
                              const isActivePage = activePage === page;              
                              const ariaLabel= 'slider-button-'+page;              
                              return (              
                                <span  aria-label={ariaLabel} className={clsx('rec-dot', isActivePage ? 'rec-dot_active': '')} key={page}              
                                onClick={() => onClick(page)}              
                                active={isActivePage} />              
                              )})}              
                          </div>             
                        )              
                      }}
                          >
                              {
                                  items.map((item, i) => <Item key={i} item={item} />)
                              }
                          </Carousel>
                      </Grid>
                  </Grid>

                  {/* More Blogs Text */}
                  <Grid container spacing={1}>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <Button className='common-btn'>read more</Button>
                      </Grid>
                  </Grid>

              </Grid>

              
          </Grid>
        </Container>



        

      </div>
  )


  function Item(props) {
      console.log("data", props)
      return (
          <>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="cols-alignment">
                  <Card className="root">
                      <CardActionArea>
                          <div className="blog-image">
                              <CardMedia component="img"
                                  image={props.item.image}
                              />
                              <div className="feature-txt-pos">
                                  <Typography className="featured-txt">
                                      {props.item.bookmark}
                                  </Typography>
                              </div>
                          </div>
                          <CardContent className="card-content">
                              <Grid container spacing={2} >
                                  <Grid item direction="column" xl={12} lg={12} md={12} sm={12} xs={12}>
                                      <Typography gutterBottom variant="subtitle1" component="div" className="card-content-text">
                                          {props.item.title}
                                      </Typography>
                                      <Typography variant="body2" gutterBottom className="card-content-date">
                                          {props.item.pubDate}
                                      </Typography>
                                      <Typography variant="sunbtitle1" color="text-secondary" component="p"
                                          className="card-content-description">
                                          {props.item.description}
                                      </Typography>
                                  </Grid>
                              </Grid>
                          </CardContent>
                      </CardActionArea>
                  </Card>
              </Grid>
          </>
      )
  }
}


