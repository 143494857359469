import React from "react";
import './HomeOurJewels.scss'
import Grid from '@material-ui/core/Grid';
import Button from '@mui/material/Button';
import Typography from '@material-ui/core/Typography';
import HomeQuoteSliderRight from '../HomeQuoteSliderRight';
import JewelsThumb1 from 'assets/JewelsThumb1.png';
import JewelsThumb2 from 'assets/JewelsThumb2.png';
import JewelsThumb3 from 'assets/JewelsThumb3.png';
import JewelsThumb4 from 'assets/JewelsThumb4.png';
import JewelsThumb5 from 'assets/JewelsThumb5.png';
import Container from '@material-ui/core/Container';
import ImageSlider from '../../../staticcomponents/image-slider/index';


export default function HomeOurService() {
  const ImgArray= [JewelsThumb1,JewelsThumb2,JewelsThumb3,JewelsThumb4,JewelsThumb5]
  return (
    <>
    <Container maxWidth="xl" className="container-padding-none">
    <Grid className="our-jewels-cls">
    <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>
      <Grid item xl={3} lg={3} md={4} sm={5} xs={6}>
        <h2 className="heading">our shining jewels</h2>
        <h3 className="sub-title">mobility = cartus</h3>
      </Grid>
      <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>
        <ImageSlider ImageArray={ImgArray}/>
      </Grid>
    </Grid>
    </Grid>
    </Container>
    {/* Slider Section Start  */}
    <Container maxWidth="xl">
    <HomeQuoteSliderRight/>
    </Container>
   
    <div className="clear-fix"></div>
      {/* Let's Talk Section Start  */}
      <div className="lets-talk-cls">
        <div className="letstalk-block">
        <div className="overlay">
        <Container  maxWidth="xl"  className="lets-talk-container">
          <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} >
            <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>
            <div className="details">
                <Grid item container xl={4} lg={5} md={6} sm={6} xs={8} >
                  <Typography className="title">connect today with us to build meaningful employee experiences</Typography>
                  <Button variant="contained" className="btn-more">let's talk</Button>
                </Grid>
                </div>

            </Grid>
          </Grid>
      </Container>

        </div>

        </div>

      </div>


    </>
  )
}


