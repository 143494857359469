import React from 'react'
import './HomeOurProud.scss'
import { Grid, Typography, Box, Card, CardActionArea, CardMedia } from '@material-ui/core';
import ImageSlider from '../../../staticcomponents/image-slider/index';
import Image1 from '../../../assets/Ourproud-university.png';
import Image2 from '../../../assets/OurProud-publix.png';
import Image3 from '../../../assets/Ourproud-game.png';
import Image4 from '../../../assets/ourproud-globle.png';
import Image5 from '../../../assets/ourproud-hrprank.png';
import Container from '@material-ui/core/Container';

export default function HomeOurUnelenting() {
  const ImgArray = [Image1, Image2, Image3, Image4, Image5]
  return (
    <>
      <Container maxWidth="xl" className='container-padding-none'>
        <Grid className="homeOurProud_cls">
          <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>
            <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} spacing={2}>
              <Grid item xl={4} lg={4} md={5} sm={6} xs={8}>
                <h2 className='title'> our proud association</h2>
              </Grid>
            </Grid>
            <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <h3 className="sub-title">moving people who move the world</h3>
              </Grid>
            </Grid>
            <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} spacing={2}>
              <ImageSlider ImageArray={ImgArray} />
            </Grid>
          </Grid>

        </Grid>
      </Container>

    </>
  )

  function Item(props) {
    return (
      <>
        <Card className="root" >
          <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} className="" direction="column" >
            <div className="Ourslide_img">
              <img src={props.item.image} />
            </div>
          </Grid>
        </Card>
      </>
    )
  }
}


