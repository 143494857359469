import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CloseIcon from "@material-ui/icons/Close";
import { Button, Dialog, DialogContent, Slide, IconButton, Box } from '@material-ui/core';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import './HomeIntro.scss'
import our_solutions_img from '../../../assets/ilyuza-mingazova.png';
import Container from '@material-ui/core/Container';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function HomeIntro() {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
   
      {/* <feel secure with cartus section> */}
      <Container maxWidth="xl" className='padding-none'>
      <Grid container className='secure-container'>
        <Grid item container xs={12} sm={12} md={12} lg={6} xl={5}>
           <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid item xs={5} sm={4} md={3} lg={6} xl={6}>
            <h2 className='title'>feel secure with cartus</h2>
          </Grid>
            <h3 className='sub-title'>guiding your business forward</h3>
            <p>Whether you’re designing your first mobility program or
              relocating thousands of executives people each year, Cartus delivers proactive dynamic,
              customized flexible solutions that meet your unique needs, every time.It’s why we guide your
              employees through life’s biggest changes - home-finding assistance, intercultural and
              language training, settling-in services.</p>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={7}>
          <div className='image-box'>
            <Paper elevation={0} className="video-container">
              <Button className="button-container" onClick={handleClickOpen}>
                <img src={our_solutions_img}
                  alt="video"
                  className="video-img" />
                <PlayCircleIcon fontSize="large" className="play-button-icon" />
              </Button>
              <Dialog open={open} TransitionComponent={Transition} onClose={handleClose} maxWidth="md">
                <Box className="popup-overlay">
                  <IconButton className="popup-close-icon" onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                  <DialogContent >
                    <Grid container className='dialog-content'>
                      <div className="iframe-class">
                        <iframe
                          width="560"
                          height="349"
                          className="responsive-iframe"
                          src="https://cartus.qumucloud.com/view/LAPdobvOgaG?autoPlay=true&embed=true&host=widgets&iframeName=kv-video-player-1-iframe-9247717&autoscale=true&AppProperties.PlayerShowEmbedButton=false" />
                      </div>
                    </Grid>
                  </DialogContent>
                </Box>
              </Dialog>
              <Grid>
                <Paper elevation={0}>
                  <Typography className="video-title">our solutions</Typography>
                  <Typography className="video-subtitle">how cartus helped erica debonair</Typography>
                </Paper>
              </Grid>

            </Paper>
          </div>
        </Grid>
      </Grid>
      </Container>

    </>
  )
}


