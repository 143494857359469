import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { Button, Slide, Box } from '@material-ui/core';
import './HomeSlider.scss'
import circle_img from '../../../assets/home-page-hero-image.png';
import circle_img_shadow from '../../../assets/home-page-hero-image-1.png';
import Container from '@material-ui/core/Container';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function Homeslider() {
  return (
    <>
      {/* <grid for image background> */}
      <Grid className='home-slider-cls'>
        <Container maxWidth="xl" className='slider-padding-none'>
          <Grid container className='slider-container'>

          <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
            <div className='slider-image'>
              <img src={circle_img_shadow} alt="where mobility meets agility" width="100%" />
            </div>
          </Grid>
          {/* <Grid sm={4} md={1} lg={12} xl={1}></Grid> */}

          <Grid item xs={12} sm={10} md={5} lg={5} xl={5}>
            <Grid className='hero-image-content'>
            <h1 className='title'>welcome to Cartus</h1>
            <h3 className='sub-title'>where mobility meets agility</h3>
            <p>Cartus provides more than relocation services. We offer trusted guidance and expertise that transform mobility into outstanding and compassionate experiences—creating opportunities for you and your employees to live better, work smarter, and contribute more.</p>

            <Button className='common-button'>learn more</Button>
            </Grid>
          </Grid>
          <Grid item sm={2} xl={1}></Grid>

          </Grid>
        </Container>
        
      </Grid>
    </>
  )
}


