import React from "react";
import './HomeOurService.scss'
import Grid from '@material-ui/core/Grid';
import Button from '@mui/material/Button';
import Typography from '@material-ui/core/Typography';
import Thumb1 from 'assets/ourservice-thumb1.png';
import Thumb2 from 'assets/ourservice-thumb2.png';
import Thumb3 from 'assets/ourservice-thumb3.png';
import Thumb4 from 'assets/ourservice-thumb4.png';
import Plus from 'assets/plusIcon.png';
import Container from '@material-ui/core/Container';


export default function HomeOurService() {
  return (
    <>
    <div className="our-service-cls">
    <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} className="capsules-bg"></Grid>
       
            <Container maxWidth="xl" className="container-padding-none">
           
        <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>
          <Grid item xl={5} lg={5} md={5} sm={7} xs={8}>
            <h2 className="service-heading">our service enablers for all situations</h2>
          </Grid>
          <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} className="row-cls row-reverse-cls">
            {/* Column One */}
            <Grid item xl={8} lg={8} md={8} sm={12} xs={12} className="left-section">
              <h3 className="sub-title">our service enablers for all situations</h3>
              <Typography className="desp">Maecenas vestibulum felis in lacus pharetra, eget laoreet purus lobortis.
                Sed eget diam eu urna hendrerit euismod eu et nulla. Etiam consectetur maximus porta.
                Cras sit amet efficitur tortor, at placerat tortor. Curabitur in quam eu justo viverra lobortis.
                Suspendisse consectetur venenatis neque, quis vehicula augue pretium id. Sed finibus pretium tortor sagittis placerat.
                Sed vitae libero vestibulum, dictum turpis in, lobortis libero. In commodo vitae diam vel scelerisque.
                Sed eget diam eu urna hendrerit euismod eu et nulla. Etiam consectetur maximus porta.
                Cras sit amet efficitur tortor, at placerat tortor. Curabitur in quam eu justo viverra lobortis.
                Suspendisse consectetur venenatis neque, quis vehicula augue pretium id.</Typography>
              <Button variant="contained" className="btn-more">learn more</Button>
            </Grid>

            {/*Column Two */}
            <Grid item xl={4} lg={4} md={4} sm={6} xs={6} className="right-sec">
              <div className="thumb-wrap mrg-top">
                <img src={Thumb1} alt="Thumb Image" />
                <Typography className="title">pre-decision</Typography>
              </div>
            </Grid>
          </Grid>

          <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} className="row-cls">
            {/* Column One */}
            <Grid item container xl={8} lg={8} md={8} sm={12} xs={12} >
              <Grid item xl={6} lg={6} md={6} sm={6} xs={6} className="img-sec">
                <div className="thumb-wrap">
                  <img src={Thumb2} alt="Thumb Image" />
                  <Typography className="title">departure</Typography>
                </div>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={6} className="img-sec">
                <div className="thumb-wrap">
                  <img src={Thumb3} alt="Thumb Image" />
                  <Typography className="title">destination</Typography>
                </div>
              </Grid>
            </Grid>

            {/*Column Two */}
            <Grid item xl={4} lg={4} md={4} sm={6} xs={6} className="img-sec lang-section">
              <div className="thumb-wrap">
                <img src={Thumb4} alt="Thumb Image" />
                <Typography className="title"><img src={Plus} alt="Icon Image" className="plus-img" /><br /> intercultural & language</Typography>
              </div>
            </Grid>
          </Grid>

        </Grid>
      
      
      </Container>
      
    </div>


    </>
  )
}


