import React from 'react';
import Layout from '../staticcomponents/layout'
import Homeslider from '../templates/homepage/Homeslider'
import HomeIntro from '../templates/homepage/HomeIntro'
import HomeOurUnique from '../templates/homepage/HomeOurUnique'
import HomeQuoteSlider from '../templates/homepage/HomeQuoteSlider'
import HomeOurService from '../templates/homepage/HomeOurService'
import HomeOurProud from '../templates/homepage/HomeOurProud'
import HomeOurUnelenting from '../templates/homepage/HomeOurUnelenting'
import HomeOurJewels from '../templates/homepage/HomeOurJewels'
import './style.scss';


export default function Home() {
 
  return (
    <>
      <Layout>
        <div className='home-main-block'>
          <div className='home-dotmatrix'>
          <Homeslider />
          </div>
          <HomeIntro />
          <HomeOurUnique />
          <div className='home-main-bg'>
            <HomeQuoteSlider />
            {/* <div className='capsules-bg-right'></div> */}
            <HomeOurService />
            
          </div>
          <HomeOurProud />
          <div className='bg-donuts-right'>
          <HomeOurUnelenting />
          <HomeOurJewels />
          </div>
          </div>

        
      </Layout>
    </>
  )
}

