import React from 'react';
import { Grid, Typography, Box, Card, CardActionArea, CardContent, CardMedia } from '@material-ui/core';
import Carousel from 'react-elastic-carousel';
import './HomeQuoteSliderRight.scss'
import ImageOne from '../../../assets/sliderImg.png';
import InvertedComma from '../../../assets/quote-icon.png';

export default function HomeQuoteSliderRight() {

  // breakpoints
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 599, itemsToShow: 1 },
    { width: 904, itemsToShow: 1 },
    { width: 1239, itemsToShow: 1 }
  ];

  const state = {
    items: [
      {
        id: 1,
        image: ImageOne,
        bookmark: 'Krisha Harbinger 1',
        title: "sales partner, swindon, UK",
        description: "Maecenas vestibulum felis in lacus pharetra, eget laoreet purus lobortis. Sed eget diam eu urna hendrerit euismod eu et nulla. Etiam consectetur maximus porta. scelerisque. Curabitur quam nulla, bibendum eu felis ac, porttitor blandit risused insignia magna."
      },

      {
        id: 2,
        image: ImageOne,
        bookmark: 'Krisha Harbinger 2',
        title: "sales partner, swindon, UK",
        description: "Maecenas vestibulum felis in lacus pharetra, eget laoreet purus lobortis. Sed eget diam eu urna hendrerit euismod eu et nulla. Etiam consectetur maximus porta. scelerisque. Curabitur quam nulla, bibendum eu felis ac, porttitor blandit risused insignia magna."
      },
      {
        id: 3,
        image: ImageOne,
        bookmark: 'Krisha Harbinger 3',
        title: "sales partner, swindon, UK",
        description: "Maecenas vestibulum felis in lacus pharetra, eget laoreet purus lobortis. Sed eget diam eu urna hendrerit euismod eu et nulla. Etiam consectetur maximus porta. scelerisque. Curabitur quam nulla, bibendum eu felis ac, porttitor blandit risused insignia magna."
      },
    ]
  }
  const { items } = state;
  return (
    <div>
      <Grid container spacing={1} direction={"row-reverse"} >
        <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} spacing={2} >
          <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} spacing={2} className="carousel-main-cls">
            <Carousel breakPoints={breakPoints}>
              {
                items.map((item, i) => <Item key={i} item={item} />)
              }
            </Carousel>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )

  function Item(props) {
    return (
      <>
        <Grid container xl={12} lg={12} md={12} sm={12} xs={12} className='jewels-slider-wrap'>
          <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} className='jewels-slider'>
            <Grid item xl={7} lg={6} md={7} sm={12} xs={12} className='info-section'>
              <CardContent className="author-details-reverse">
                <div className='testimonial-img-logo'>
                  <img src={InvertedComma} className='comma-img' />
                  <Typography className='testimonial-descp'> {props.item.description}</Typography>
                </div>
                <div className="author-details">
                  <Typography className='author-name'> {props.item.bookmark} </Typography>
                  <Typography className='other-details'> {props.item.title} </Typography>
                </div>
              </CardContent>
            </Grid>
            <Grid item xl={5} lg={6} md={5} sm={12} xs={12} className='img-section'>
              <CardMedia component="img" image={props.item.image} />
            </Grid>
          </Grid>
        </Grid>
      </>
    )
  }
}


