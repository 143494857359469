import { makeStyles } from '@material-ui/core';

export const myStyles = makeStyles((theme) => ({

  root: () => ({
    background: `transparent linear-gradient(89deg, #009ADF 0%, #009A95 100%) 0% 0% no-repeat padding-box;`,
    opacity: 1,
    cursor: 'pointer',
    left: '0px',
    // width: '1378px',
    width: '100%',

    [theme.breakpoints.up('xs')]: {
      height: '689px',
    },
    [theme.breakpoints.up('sm')]: {
      height: '459px',
    },
    [theme.breakpoints.up('md')]: {
      height: '459px',
    },
    [theme.breakpoints.up('xl')]: {
      height: '459px',
      borderRadius: `0px 400px 400px 0px !important`,
    },
    [theme.breakpoints.up('lg')]: {
      height: '459px',
      borderRadius: `0px 400px 400px 0px !important`,
    }
  }),

  Quotesliderinfo: () => ({
    padding: '35px 65px !important',
  }),

  QuotesliderImg: () => ({

    [theme.breakpoints.up('xs')]: {

      width: `200px`,
      height: `200px`,
      marginLeft: `7%`,
      borderRadius: '200px',
    },
    [theme.breakpoints.up('sm')]: {

      width: `200px`,
      height: `200px`,
      borderRadius: '200px',
    },
    [theme.breakpoints.up('md')]: {

      width: `200px`,
      height: `200px`,
      borderRadius: '200px',
    },
    [theme.breakpoints.up('xl')]: {
      borderRadius: '400px !important',
      width: `400px !important`,
      height: `400px !important`,

    },
    [theme.breakpoints.up('lg')]: {
      borderRadius: '400px !important',
      width: `400px !important`,
      height: `400px !important`,
    }
  }),

  Quotesliderdatadisplay: () => ({
    color: '#ffffff',
    fontSize: '16px',
    textAlign: `left`,
    padding: '30px 0px !important',
    fontFamily: 'New Order, Semibold',
    letterSpacing: `0.12px`,
    opacity: `1`,
    font: `normal normal 600 24px/36px New Order;`
  }),


  QuoteslidertestimonialIcon: () => ({
    width: '67px',
    height: '38px',
    fontSize: '80px',
    fontFamily: 'Gill Sans, Bold',
    color: '#fff'
  }),

  carouselMainCont: {
    "& .rec-dot_active": {
      backgroundColor: "#2098D5",
      border: "1px solid #2098D5",
      boxShadow: "0 0 1px 3px rgba(32, 152, 213, 1)"
    },
    "& .rec .rec-dot": {
      border: "1px solid #2098D5",
      boxShadow: "0 0 1px 1px rgba(32, 152, 213, 1)"
    },
    "& .rec .rec-pagination": {
      margin: '50px 0'
    },
    "& .rec .rec-arrow-left": {
      display: 'none',
    },

    "& .rec .rec-arrow-right": {
      backgroundColor: '#209009ADF8D5',
      color: '#fff',
      boxShadow: '0 0 2px 0px #2098d5',
    },



  }
}));